#socialLinks {
  position: fixed;
  right: 1rem;
  bottom: 0;
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  padding: .5rem 1rem;
  background: $lt-blue;
  max-width: 13.1rem;
  width: 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  div {
    flex: 1;
    margin: 0 1rem 0 0;

    &:last-of-type {
      margin-right: 0;
    }

    a {
      display: block;
    }

    svg {
      width: auto;
      height: 3.2rem;
    }
  }

  > p {
    font-family: $economica;
    flex: 0 0 100%;
    text-align: center;
    text-transform: uppercase;
  }
}