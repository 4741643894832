/* fonts */
@font-face {
  font-family: Economica;
  src: url(../src/assets/fonts/Economica-Regular.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Roboto;
  src: url(../src/assets/fonts/Roboto-Regular.ttf);
  font-weight: 400;
}