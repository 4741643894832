footer {
  font: italic normal 1.6rem $economica;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 5rem;
  background: $lt-blue;
  color: $white;

  @include sm-mobile-down {
    justify-content: flex-start;
  }

  p {
    font-size: 1.5rem;
    padding: 0 1rem;

    @include xs-mobile {
      font-size: 1.4rem;
      max-width: 182px;
    }
  }
}