// Responsive Breakpoints
$xs-width: 480px;
$mobile-width: 640px;
$sm-width: 768px;
$md-width: 980px;
$lg-width: 1280px;

$root-font-size: 10px;

//Colors
$black: #000;
$white: #fff;

//Theme Colors
$drk-black: #1B2226;
$lt-gray: #74777E;
$lt-blue: #6380A6;
$blue: #748FB5;
$lt-orange: #F2A25C;
$drk-orange: #FF6E30;

// Fonts
$economica: 'Economica', sans-serif;
$roboto: 'Roboto', sans-serif;